#logo {
	max-width: 100%;
}

#headline {
	max-width: 550px;
	margin: 0 auto;
}

.margin-top-sm {
	margin-top: 25px;
}

.margin-top {
	margin-top: 50px;
}

.margin-bottom {
	margin-bottom: 50px;
}

.product-logo {
	max-width: 300px;
}